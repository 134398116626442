
.timeline-overflow {
  @apply w-full border-t-0 overflow-y-hidden;
  height: 100px;
  max-height: 100px;
  overflow-x: hidden; /* Windows Chrome/Firefox stupidity https://stackoverflow.com/a/24911864/220287 */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* IE/Edge stupidity */
}

/* Windows Chrome/Firefox stupidity https://stackoverflow.com/a/24911864/220287 */
.timeline-overflow:hover {
  overflow-x: auto;
}

.timeline-container {
  @apply h-full;
}

.timeline-event {
  @apply h-full float-left text-center text-sm;
  width: 99px;
  padding: 3px;
  background-color: #c8beac;
  /* background-image: linear-gradient(to right,#c8beac,#beb29d); */
  border-top: 6px solid transparent;
  border-right: 1px solid #22222240;
  border-bottom: 6px solid transparent;
  color: #666;
}

.timeline-event.wide {
  width: 112px;
}

.current-event {
  border-bottom-color: #222;
}

.timeline-event:hover {
  @apply cursor-pointer;

  border-bottom: 6px solid #111;
  transition: border-bottom 0.25s ease-out;
}

/* .timeline-event.highlight {
  color: #222;
  background-color: #efeade;
  background-image: linear-gradient(to right,#efeade,#e6decc);
}*/

.timeline-event.highlight:hover {
  
  border-bottom: 6px solid #000;
  transition: border-bottom 0.25s ease-out;
}

.timeline-event p {
  @apply mb-0 overflow-hidden overflow-ellipsis whitespace-nowrap leading-5;
}

.timeline-event p.eventTime {
  @apply font-black;
}

.timeline-event p.eventAzimuth {
  @apply italic;
}

.timeline-event p.eventDetails {
  @apply h-auto text-xs whitespace-nowrap leading-5 mb-0 overflow-ellipsis;
} 

/* https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4 */
/* .timeline-event svg {
  @apply box-content pr-px mr-px fill-current w-[0.85rem] h-[0.85rem];
} */