@tailwind base;
@tailwind components;

@layer base {

  @font-face {
    font-family: 'Cabin';
    src: url('fonts/cabin/cabin-regular-webfont.woff2') format('woff2'),
         url('fonts/cabin/cabin-regular-webfont.woff') format('woff');
    font-weight: 100 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Cabin';
    src: url('fonts/cabin/cabin-italic-webfont.woff2') format('woff2'),
         url('fonts/cabin/cabin-italic-webfont.woff') format('woff');
    font-weight: 100 400;
    font-style: italic;
    font-display: swap;
  }
  
  /* @font-face {
    font-family: 'Cabin';
    src: url('fonts/cabin/cabin-medium-webfont.woff2') format('woff2'),
         url('fonts/cabin/cabin-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  } */
  
  /* @font-face {
    font-family: 'Cabin';
    src: url('fonts/cabin/cabin-mediumitalic-webfont.woff2') format('woff2'),
         url('fonts/cabin/cabin-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  } */
  
  /* @font-face {
    font-family: 'Cabin';
    src: url('fonts/cabin/cabin-semibold-webfont.woff2') format('woff2'),
         url('fonts/cabin/cabin-semibold-webfont.woff') format('woff');
    font-weight: 500 600;
    font-style: bold;
    font-display: swap;
  } */
  
  /* @font-face {
    font-family: 'Cabin';
    src: url('fonts/cabin/cabin-semibolditalic-webfont.woff2') format('woff2'),
         url('fonts/cabin/cabin-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  } */
  
  @font-face {
    font-family: 'Cabin';
    src: url('fonts/cabin/cabin-bold-webfont.woff2') format('woff2'),
         url('fonts/cabin/cabin-bold-webfont.woff') format('woff');
    font-weight: 700 900;
    font-style: bold;
    font-display: swap;
  }
  
  /* @font-face {
    font-family: 'Cabin';
    src: url('fonts/cabin/cabin-bolditalic-webfont.woff2') format('woff2'),
         url('fonts/cabin/cabin-bolditalic-webfont.woff') format('woff');
    font-weight: 700 900;
    font-style: italic;
    font-display: swap;
  } */

  @font-face {
    font-family: 'CormorantGaramond';
    src: url('fonts/cormorant/CormorantGaramond-Light.woff2') format('woff2'),
         url('fonts/cormorant/CormorantGaramond-Light.woff') format('woff');
    font-weight: 100 400;
    font-style: normal;
    font-display: swap;
  }

  /* @font-face {
    font-family: 'CormorantGaramond';
    src: url('fonts/cormorant/CormorantGaramond-Regular.woff2') format('woff2'),
         url('fonts/cormorant/CormorantGaramond-Regular.woff') format('woff');
    font-weight: 500 900;
    font-style: normal;
    font-display: swap;
  } */

  @font-face {
    font-family: 'CormorantGaramond';
    src: url('fonts/cormorant/CormorantGaramond-LightItalic.woff2') format('woff2'),
         url('fonts/cormorant/CormorantGaramond-LightItalic.woff') format('woff');
    font-weight: 100 400;
    font-style: italic;
    font-display: swap;
  }

  /* @font-face {
    font-family: 'CormorantGaramond';
    src: url('fonts/cormorant/CormorantGaramond-Italic.woff2') format('woff2'),
         url('fonts/cormorant/CormorantGaramond-Italic.woff') format('woff');
    font-weight: 500 900;
    font-style: italic;
    font-display: swap;
  } */

  html {
    @apply text-base box-border;
  }

  body {
    @apply bg-tpe-dark font-normal p-0 m-0 leading-5;
    /* To avoid pointless scroll bars on Windows machines: see https://stackoverflow.com/a/49278385/220287 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }

  body::-webkit-scrollbar {
    display: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-light;
  }

  h1, h2, h3 {
    @apply mt-7 mb-3;
  }

  h4, h5, h6 {
    @apply mt-4 mb-2;
  }

  h1 {
    @apply text-6xl;
  }

  h2 {
    @apply text-5xl;
  }

  h3 {
    @apply text-4xl;
  }

  h4 {
    @apply text-3xl;
  }

  h5 {
    @apply text-2xl;
  }

  h6 {
    @apply text-xl;
  }

  p {
    @apply leading-normal mt-0 mb-1;
  }

  a {
    @apply no-underline text-curious-blue hover:text-curious-blue-600;
  }

  a svg {
    @apply fill-current stroke-current;
  }

  label, legend {
    @apply block mt-1 mb-2 font-semibold;
  }

  textarea {
    @apply py-2 resize-y min-h-16;
  }

  blockquote {
    @apply mx-10 my-4;
  }

  svg {
    @apply inline;
  }

  input, textarea, select, fieldset, pre,blockquote, dl, figure, table, ul, ol, form {
    @apply mb-1 focus:ring-2 focus:ring-curious-blue-600 focus:border-curious-blue-600;
  }

  input[type="email"],
  input[type="text"],
  input[type="password"],
  input[type="number"],
  textarea,
  select {
    @apply block w-full h-8;
  }

  form input, form button {
    @apply mt-1 mb-2;
  }

  [type='checkbox'], [type='radio'] {
    @apply text-curious-blue;
  }

  [type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select, [type='checkbox'], [type='radio'] {
    @apply rounded border-regent-gray-300 focus:ring-2 focus:ring-curious-blue-600 focus:border-curious-blue-600;
  }

  [type='text'], select {
    @apply px-2 py-1;
  }

  button {
    @apply inline-block h-8 py-0 px-4 text-gray-600 text-xs text-center font-semibold tracking-widest uppercase no-underline whitespace-nowrap bg-transparent rounded border border-solid border-gray-300 cursor-pointer transition-border;
    @apply focus:text-gray-700 focus:border-gray-500 focus:outline-none;
    @apply hover:text-gray-700 hover:border-gray-500;
  }
  
  hr {
    @apply mt-4 mb-5 border-0 border-t border-solid border-regent-gray-200;
  }

  ul {
    @apply list-square list-inside pl-0 mt-2;
  }

  ol {
    @apply list-decimal list-inside pl-0 mt-2;
  }

  li {
    @apply mb-2;
  }

  table {
    @apply w-full border-collapse border-0;
    -webkit-overflow-scrolling: touch;
  }

  tbody, tr, td {
    @apply border-0;
  }

  th, td {
    @apply my-3 mx-4;
  }

  td {
    @apply border-0 border-b border-gray-200 pt-2;
  }

  th {
    @apply text-left;
  }

  th:first-child,
  td:first-child {
    @apply pl-0 sm:pl-4;
  }

  th:last-child,
  td:last-child {
    @apply pr-0;
  }

  tr th {
    @apply border-0 border-b border-black py-2;
  }

}

/* See https://stackoverflow.com/a/68728920/220287 */
@tailwind utilities;