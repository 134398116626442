header {
  @apply box-border w-full py-1 px-2 text-white bg-tpe-dark focus:outline-none;
  background: var(--color-app-background) url(2020-header-webapp.jpg) no-repeat top center;
  background-size: cover;
}

header h3 {
  @apply m-0 flex-none;
  font-size: calc(1.1rem + 0.9vw);
  /* flex: 0 0 auto; */
}

header .headerContainer {
  /* sm:-mb-3 this corresponds to the rule sm:-top-3 applied to .app-logo below - without it, the headerContainer div is taller than necessary*/
  @apply flex justify-start items-center uppercase mt-[21px] ml-20 mb-1;
}

.app-logo {
  @apply flex-none absolute top-0 left-0 w-20 h-20 ml-2 my-0 box-border pointer-events-none;
  background: url(logo64.png) no-repeat center;
  background-size: 4rem;
}

.logotype.photo, .logotype.photo a {
	color: #CBCBCB;
  @apply tracking-wider;
}

.logotype.ephemeris, .logotype.ephemeris a {
  @apply text-white font-bold tracking-widest;
}

.nav-links {
  @apply pl-2 text-right z-50 flex-auto;
  font-size: calc(0.9rem + 0.08vmin);
}

.nav-links label {
  @apply inline-block relative p-0 pb-2 cursor-pointer bg-white;
  mask: url(glyphicons-basic-600-menu.svg) no-repeat 50% 50%;
}

input#menu {
  @apply hidden;
}

input#menu:checked +label {
  @apply bg-white;
  mask: url(glyphicons-basic-599-menu-close.svg) no-repeat 50% 50%;
  /* background: url(glyphicons-basic-599-menu-close.svg) no-repeat left center; */
}

.nav-links-content {
  @apply hidden lg:inline-block absolute lg:relative bg-tpe-dark lg:bg-transparent rounded top-20 lg:top-0 right-4 lg:right-0;
}

.nav-links ul {
  @apply m-0 p-0 lg:pt-1 lg:px-0;
}

.nav-links li {
  @apply lg:inline-block m-0 py-2 px-2 sm:px-4 list-none;
}

.nav-links a {
  @apply no-underline;
}

.nav-links label {
  @apply lg:hidden;
}

/* Display the menu when the input is checked */
.nav-links input:checked ~ .nav-links-content {
  display: block;
}

