
.event.sunrise, .event.sunset {
  background-color: #E6D9BC;
  color: #222;
}

.event.transit, .event.solstice, .event.equinox {
  background-color: #EEEBDE;
  color: #333;
}

.event.golden_start, .event.golden_end {
  background-color: #F1E8D6;
  color: #333;
}

.event.civil_rise, .event.civil_set {
  background-color: #7B91A6;
  color: #FFFFFF;
  text-shadow: 1px 1px 0px #22222288;
}

.event.nautical_rise, .event.nautical_set {
  background-color: #66798A;
  color: #FFFFFF;
  text-shadow: 1px 1px 0px #22222288;
}

.event.astro_rise, .event.astro_set {
  background-color: #525D69;
  color: #FFFFFF;
  text-shadow: 1px 1px 0px #22222288;
}

.event.moonrise, .event.moonset, .event.full_moon, .event.new_moon, .event.last_quarter, .event.first_quarter {
  background-color: #D8DEE1;
  color: #222;
}

.event.lunartransit, .event.lunar_apogee, .event.lunar_perigee {
  background-color: #DFDFDF;
  color: #333;
}

.event.cmv_best {
  background-color: #DBDFE1;
  color: #333;
}

.event.galactic_centre_rise, .event.galactic_centre_set, .event.galactic_centre_transit {
  background-color: #444;
  color: #FFFFFFE6;
  text-shadow: 1px 1px 0px #33333388;
}

.event.moon_penumbral_first_contact, .event.moon_penumbral_last_contact {
  background-color: #ACACAC;
  color: #FFFFFFE6;
  text-shadow: 1px 1px 0px #33333388;
}

.event.moon_umbral_first_contact, .event.moon_umbral_last_contact {
  background-color: #919191;
  color: #FFFFFFE6;
  text-shadow: 1px 1px 0px #33333388;
}

.event.moon_total_eclipse_start, .event.moon_total_eclipse_end {
  background-color: #BC2906;
  color: #FFFFFFE6;
  text-shadow: 1px 1px 0px #33333388;
}

.event.moon_max_eclipse_time {
  background-color: #6A6A6A;
  color: #FFFFFFE6;
  text-shadow: 1px 1px 0px #33333388;
}

.event.moon_max_eclipse_time.total {
  background-color: #A62306;
  color: #FFFFFF;
  text-shadow: 1px 1px 0px #22222288;
}

.event.moon_max_eclipse_time.partial {
  background-color: #808080;
  color: #FFFFFF;
  text-shadow: 1px 1px 0px #22222288;
}

.event.moon_max_eclipse_time.penumbral {
  background-color: #999999;
  color: #FFFFFFE6;
  text-shadow: 1px 1px 0px #33333388;
}
