footer {
  @apply w-full flex flex-row flex-wrap justify-center items-center align-baseline box-border pt-1 pb-1.5 px-4 text-regent-gray-500 text-sm focus:outline-none;
  /* height: var(--footer-height); */
  /* min-height: 40px; */
  background: var(--color-app-background) url(footer-background.png) no-repeat center left;
  background-size: 184px; /* as the image is not square, this is a 'fiddle factor' to right-size the background image*/
}

footer ul {
  @apply text-center w-auto list-none my-1 mx-1;
}

footer li {
  @apply inline;
}

footer ul.blurb li:not(:first-of-type):before {
  content: "\2009\00B7\2009";
}

footer svg.icon {
  @apply mx-2;
}

footer svg path {
  @apply text-regent-gray-400 fill-current stroke-current;
}