/* http://reactcommunity.org/react-modal/styles/classes/ */

.reactModalOverlay {
  @apply fixed top-0 left-0 right-0 bottom-0 z-50 bg-regent-gray-900 bg-opacity-90;
}

.reactModalContent {
  @apply absolute top-1/2 bottom-auto left-1/2 right-auto p-5 border border-solid border-regent-gray-400 rounded bg-white overflow-auto outline-none min-w-[600px];
  transform: translate(-50%,-50%);
  /* margin-right: -50%; */
  -webkit-overflow-scrolling: touch;
  max-width: 98% !important;
} 

/* http://reactcommunity.org/react-modal/styles/transitions/ */

.ReactModal__Overlay {
  @apply opacity-0 transition-opacity;
}

.ReactModal__Overlay--after-open {
  @apply opacity-100;
}

.ReactModal__Overlay--before-close {
  @apply opacity-0;
}