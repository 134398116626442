

#iubenda-iframe *, #iubenda-iframe #iubenda-iframe-content * {
  font-family: Cabin, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica\ Neue, Arial, sans-serif !important;
}

#iubenda-iframe-content #purposes-content-container .purposes-buttons button.purposes-btn-accept, #iubenda-iframe-content  #purposes-content-container .purposes-checkbox input.style1:checked {
  background-color: #36A02D!important;
  color: #ffffff!important;
}

#iubenda-iframe #iubFooterBtnContainer #iubFooterBtn {
  background-color: #01b2da!important;
}