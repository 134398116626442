/*! skeleton-framework | 1.1.1 | MIT | 2/25/2017 */
/* Table of contents
- Base Styles
- Grid
- Typography
- Links
- Buttons
- Forms
- Lists
- Code
- Tables
- Spacing
- Utilities
- Clearing
- Media Queries
*/

.row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.row::before,
.row::after {
  content: ' ';
  display: table;
}

.row::after {
  clear: both;
}

[class*='column'] {
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.xs-one[class*='column'] {
  width: 8.3333333333%;
}

.xs-two[class*='column'] {
  width: 16.6666666666%;
}

.xs-three[class*='column'],
.xs-one-quarter[class*='column'] {
  width: 24.9999999999%;
}

.xs-four[class*='column'],
.xs-one-third[class*='column'] {
  width: 33.3333333332%;
}

.xs-five[class*='column'] {
  width: 41.6666666665%;
}

.xs-six[class*='column'],
.xs-one-half[class*='column'] {
  width: 49.9999999998%;
}

.xs-seven[class*='column'] {
  width: 58.3333333331%;
}

.xs-eight[class*='column'],
.xs-two-thirds[class*='column'] {
  width: 66.6666666664%;
}

.xs-nine[class*='column'] {
  width: 74.9999999997%;
}

.xs-ten[class*='column'] {
  width: 83.333333333%;
}

.xs-eleven[class*='column'] {
  width: 91.6666666663%;
}

.xs-twelve[class*='column'] {
  width: 99.9999999996%;
}

[class*='xs-'][class*='column'] ~ [class*='xs-'][class*='column'] {
  margin-top: 0;
}

@media screen and (min-width: 560px) {
  [class*='column'] ~ [class*='column'] {
    margin-top: 0;
  }
  .one[class*='column'] {
    width: 8.3333333333%;
  }
  .two[class*='column'] {
    width: 16.6666666666%;
  }
  .three[class*='column'],
  .one-quarter[class*='column'] {
    width: 24.9999999999%;
  }
  .four[class*='column'],
  .one-third[class*='column'] {
    width: 33.3333333332%;
  }
  .five[class*='column'] {
    width: 41.6666666665%;
  }
  .six[class*='column'],
  .one-half[class*='column'] {
    width: 49.9999999998%;
  }
  .seven[class*='column'] {
    width: 58.3333333331%;
  }
  .eight[class*='column'],
  .two-thirds[class*='column'] {
    width: 66.6666666664%;
  }
  .nine[class*='column'] {
    width: 74.9999999997%;
  }
  .ten[class*='column'] {
    width: 83.333333333%;
  }
  .eleven[class*='column'] {
    width: 91.6666666663%;
  }
  .twelve[class*='column'] {
    width: 99.9999999996%;
  }
  .offset-by-one[class*='column'] {
    margin-left: 8.3333333333%;
  }
  .offset-by-two[class*='column'] {
    margin-left: 16.6666666666%;
  }
  .offset-by-three[class*='column'],
  .offset-by-one-quarter[class*='column'] {
    margin-left: 24.9999999999%;
  }
  .offset-by-four[class*='column'],
  .offset-by-one-third[class*='column'] {
    margin-left: 33.3333333332%;
  }
  .offset-by-five[class*='column'] {
    margin-left: 41.6666666665%;
  }
  .offset-by-six[class*='column'],
  .offset-by-one-half[class*='column'] {
    margin-left: 49.9999999998%;
  }
  .offset-by-seven[class*='column'] {
    margin-left: 58.3333333331%;
  }
  .offset-by-eight[class*='column'],
  .offset-by-two-thirds[class*='column'] {
    margin-left: 66.6666666664%;
  }
  .offset-by-nine[class*='column'] {
    margin-left: 74.9999999997%;
  }
  .offset-by-ten[class*='column'] {
    margin-left: 83.333333333%;
  }
  .offset-by-eleven[class*='column'] {
    margin-left: 91.6666666663%;
  }
  .sm-one[class*='column'] {
    width: 8.3333333333%;
  }
  .sm-two[class*='column'] {
    width: 16.6666666666%;
  }
  .sm-three[class*='column'],
  .sm-one-quarter[class*='column'] {
    width: 24.9999999999%;
  }
  .sm-four[class*='column'],
  .sm-one-third[class*='column'] {
    width: 33.3333333332%;
  }
  .sm-five[class*='column'] {
    width: 41.6666666665%;
  }
  .sm-six[class*='column'],
  .sm-one-half[class*='column'] {
    width: 49.9999999998%;
  }
  .sm-seven[class*='column'] {
    width: 58.3333333331%;
  }
  .sm-eight[class*='column'],
  .sm-two-thirds[class*='column'] {
    width: 66.6666666664%;
  }
  .sm-nine[class*='column'] {
    width: 74.9999999997%;
  }
  .sm-ten[class*='column'] {
    width: 83.333333333%;
  }
  .sm-eleven[class*='column'] {
    width: 91.6666666663%;
  }
  .sm-twelve[class*='column'] {
    width: 99.9999999996%;
  }
  .sm-offset-by-zero[class*='column'] {
    margin-left: 0;
  }
  .sm-offset-by-one[class*='column'] {
    margin-left: 8.3333333333%;
  }
  .sm-offset-by-two[class*='column'] {
    margin-left: 16.6666666666%;
  }
  .sm-offset-by-three[class*='column'],
  .sm-offset-by-one-quarter[class*='column'] {
    margin-left: 24.9999999999%;
  }
  .sm-offset-by-four[class*='column'],
  .sm-offset-by-one-third[class*='column'] {
    margin-left: 33.3333333332%;
  }
  .sm-offset-by-five[class*='column'] {
    margin-left: 41.6666666665%;
  }
  .sm-offset-by-six[class*='column'],
  .sm-offset-by-one-half[class*='column'] {
    margin-left: 49.9999999998%;
  }
  .sm-offset-by-seven[class*='column'] {
    margin-left: 58.3333333331%;
  }
  .sm-offset-by-eight[class*='column'],
  .sm-offset-by-two-thirds[class*='column'] {
    margin-left: 66.6666666664%;
  }
  .sm-offset-by-nine[class*='column'] {
    margin-left: 74.9999999997%;
  }
  .sm-offset-by-ten[class*='column'] {
    margin-left: 83.333333333%;
  }
  .sm-offset-by-eleven[class*='column'] {
    margin-left: 91.6666666663%;
  }
}

@media screen and (min-width: 720px) {
  .md-one[class*='column'] {
    width: 8.3333333333%;
  }
  .md-two[class*='column'] {
    width: 16.6666666666%;
  }
  .md-three[class*='column'],
  .md-one-quarter[class*='column'] {
    width: 24.9999999999%;
  }
  .md-four[class*='column'],
  .md-one-third[class*='column'] {
    width: 33.3333333332%;
  }
  .md-five[class*='column'] {
    width: 41.6666666665%;
  }
  .md-six[class*='column'],
  .md-one-half[class*='column'] {
    width: 49.9999999998%;
  }
  .md-seven[class*='column'] {
    width: 58.3333333331%;
  }
  .md-eight[class*='column'],
  .md-two-thirds[class*='column'] {
    width: 66.6666666664%;
  }
  .md-nine[class*='column'] {
    width: 74.9999999997%;
  }
  .md-ten[class*='column'] {
    width: 83.333333333%;
  }
  .md-eleven[class*='column'] {
    width: 91.6666666663%;
  }
  .md-twelve[class*='column'] {
    width: 99.9999999996%;
  }
  .md-offset-by-zero[class*='column'] {
    margin-left: 0;
  }
  .md-offset-by-one[class*='column'] {
    margin-left: 8.3333333333%;
  }
  .md-offset-by-two[class*='column'] {
    margin-left: 16.6666666666%;
  }
  .md-offset-by-three[class*='column'],
  .md-offset-by-one-quarter[class*='column'] {
    margin-left: 24.9999999999%;
  }
  .md-offset-by-four[class*='column'],
  .md-offset-by-one-third[class*='column'] {
    margin-left: 33.3333333332%;
  }
  .md-offset-by-five[class*='column'] {
    margin-left: 41.6666666665%;
  }
  .md-offset-by-six[class*='column'],
  .md-offset-by-one-half[class*='column'] {
    margin-left: 49.9999999998%;
  }
  .md-offset-by-seven[class*='column'] {
    margin-left: 58.3333333331%;
  }
  .md-offset-by-eight[class*='column'],
  .md-offset-by-two-thirds[class*='column'] {
    margin-left: 66.6666666664%;
  }
  .md-offset-by-nine[class*='column'] {
    margin-left: 74.9999999997%;
  }
  .md-offset-by-ten[class*='column'] {
    margin-left: 83.333333333%;
  }
  .md-offset-by-eleven[class*='column'] {
    margin-left: 91.6666666663%;
  }
}

@media screen and (min-width: 960px) {
  .lg-one[class*='column'] {
    width: 8.3333333333%;
  }
  .lg-two[class*='column'] {
    width: 16.6666666666%;
  }
  .lg-three[class*='column'],
  .lg-one-quarter[class*='column'] {
    width: 24.9999999999%;
  }
  .lg-four[class*='column'],
  .lg-one-third[class*='column'] {
    width: 33.3333333332%;
  }
  .lg-five[class*='column'] {
    width: 41.6666666665%;
  }
  .lg-six[class*='column'],
  .lg-one-half[class*='column'] {
    width: 49.9999999998%;
  }
  .lg-seven[class*='column'] {
    width: 58.3333333331%;
  }
  .lg-eight[class*='column'],
  .lg-two-thirds[class*='column'] {
    width: 66.6666666664%;
  }
  .lg-nine[class*='column'] {
    width: 74.9999999997%;
  }
  .lg-ten[class*='column'] {
    width: 83.333333333%;
  }
  .lg-eleven[class*='column'] {
    width: 91.6666666663%;
  }
  .lg-twelve[class*='column'] {
    width: 99.9999999996%;
  }
  .lg-offset-by-zero[class*='column'] {
    margin-left: 0;
  }
  .lg-offset-by-one[class*='column'] {
    margin-left: 8.3333333333%
  }
  .lg-offset-by-two[class*='column'] {
    margin-left: 16.6666666666%
  }
  .lg-offset-by-three[class*='column'],
  .lg-offset-by-one-quarter[class*='column'] {
    margin-left: 24.9999999999%;
  }
  .lg-offset-by-four[class*='column'],
  .lg-offset-by-one-third[class*='column'] {
    margin-left: 33.3333333332%;
  }
  .lg-offset-by-five[class*='column'] {
    margin-left: 41.6666666665%;
  }
  .lg-offset-by-six[class*='column'],
  .lg-offset-by-one-half[class*='column'] {
    margin-left: 49.9999999998%;
  }
  .lg-offset-by-seven[class*='column'] {
    margin-left: 58.3333333331%;
  }
  .lg-offset-by-eight[class*='column'],
  .lg-offset-by-two-thirds[class*='column'] {
    margin-left: 66.6666666664%;
  }
  .lg-offset-by-nine[class*='column'] {
    margin-left: 74.9999999997%;
  }
  .lg-offset-by-ten[class*='column'] {
    margin-left: 83.333333333%;
  }
  .lg-offset-by-eleven[class*='column'] {
    margin-left: 91.6666666663%;
  }
}

.container:after,
.row:after {
  content: "";
  display: table;
  clear: both;
}

