/* @import url('https://fonts.googleapis.com/css?family=Cabin:400,400i,700&display=swap'); */

/* Global CSS vars */
:root {
  
  /* Fixed header and footer heights make this all work a bit better than variable with min/max values*/
  /* --header-height: 72px; */
  /* --footer-height: 40px; */
  /* --container-height: calc(100vh - var(--header-height) - var(--footer-height)); */

  /* 98vh so we can see a little body background to visually anchor things a bit better */
  --container-height-full-screen: max(98vh, 40rem);

  --color-app-background:  #282c34;
  --color-skeleton-success: #41AD27;
  --color-skeleton-danger: #e74c3c;

  --color-skeleton-cancel-sat: #191f2e;
  --color-skeleton-cancel: #3d4047;
  --color-skeleton-cancel-desat: #414244;
}

.no-focus-outline:focus, .no-focus-outline canvas:focus {
  outline: none;
}

#root {
  @apply flex flex-col h-screen overflow-y-scroll;
  /* To avoid pointless scroll bars on Windows machines: see https://stackoverflow.com/a/49278385/220287 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

#root::-webkit-scrollbar {
  display: none;
}

.container {
  @apply box-border w-full flex flex-col flex-grow mx-auto py-1 px-5 overflow-scroll bg-tpe-light focus:outline-none;
  max-width: 3600px; /* larger than Skeleton default */
  /* To avoid pointless scroll bars on Windows machines: see https://stackoverflow.com/a/49278385/220287 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.container::-webkit-scrollbar {
  display: none;
}

/* This rule is important for row heights on the Map and Sphere page - things don't grow/shrink as expected without it. (Why? Finding out now)*/
.container>div {
  @apply overflow-auto;
  /* To avoid pointless scroll bars on Windows machines: see https://stackoverflow.com/a/49278385/220287 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.container>div::-webkit-scrollbar {
  display: none;
}

.container.full-screen {
  margin-top: 1vh;
  margin-bottom: 1vh;
  /* height: var(--container-height-full-screen); */
}

.container.full-bleed {
  padding: 0;
  /* height: var(--container-height); */
  min-height: unset;
  overflow: hidden;
  background-color: #080E1C;
}

.container #rowMap {
  @apply flex flex-auto;
}

.container #rowDate, .container #rowMapInfo, .container #rowGeodetics {
  @apply overflow-visible;
}

.container #rowMap {
  min-height: 338px;
}

/* https://stackoverflow.com/questions/33636796/chrome-safari-not-filling-100-height-of-flex-parent */
.container #rowMap > .mapContainer {
  @apply flex flex-auto;
}

#rowTimeline,
#rowAltitudeChart {
  @apply overflow-visible box-border;
}

.container > #rowTimeSlider {
  @apply pt-1 pb-2;
  padding-left: 30px;
  padding-right: 30px;
}

.no-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: grab;
}

.leaflet-container, .leaflet-container a.leaflet-popup-close-button {
  @apply font-sans;
}

.btn.white { 
  @apply bg-tpe-light;
}

small, .small, button.link.small {
  @apply text-sm;
}

.text-muted {
  @apply text-regent-gray-500;
}

a.unauthorized {
  /* pointer-events: none; */
  color: rgb(88, 129, 143);
}

div[role="button"]:focus {
  outline: #aaa dashed 1px ;
}

svg.icon {
  @apply my-0 mx-1;
}

/* Buttons */

button img.icon {
  @apply h-6 w-6;
}

button.button-primary svg, button.button-primary g, button.button-primary rect, .white {
  @apply text-white fill-current stroke-current;
}

/* https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4 */
button svg, svg.icon {
  @apply h-5 w-5 box-content;
}

.loading {
  @apply ml-1 pl-5 text-regent-gray-500;
  background-image: url('./ajax-loader.gif'); /* http://www.ajaxload.info */
  background-position: left;
  background-repeat: no-repeat;
}

/* Keep lines of text from getting too long on wide displays - typically used for input forms and similar */
.readable {
  max-width: 800px
}

form.narrow {
  width: 400px;
  max-width: 98%;
}

form.narrow input {
  max-width: unset;
}

input[type="email"].readable,
input[type="text"].readable,
input[type="password"].readable,
input[type="number"].readable,
textarea.readable {
  max-width: 320px;
}

/* label {
  margin-top: 0.4rem;
} */

label.checkbox {
  @apply font-normal;
}

.errorMessage {
  @apply text-red-600;
}

.warning {
  @apply text-red-700 fill-current stroke-current;
}

svg.warning {
  @apply text-red-700 fill-current;
}

.deemphasize {
  @apply text-regent-gray-500;
}

img.deemphasize {
  @apply opacity-50;
}

svg.deemphasize, svg.deemphasize g {
  @apply text-regent-gray-500 fill-current;
}

.control-deemphasize {
  @apply text-regent-gray-500 fill-current stroke-current;
}

.requiredInputLabel:after {
  @apply text-red-700 fill-current;
  content: '\0020\002A';
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  @apply text-regent-gray-200 opacity-100;
}
::-ms-input-placeholder { /* Microsoft Edge */
  @apply text-regent-gray-200 opacity-100;
}

/* Styles previously in ephemeris.css */
.unverified {
  @apply text-red-600;
}

label.mapProviderControls {
  @apply inline text-sm text-regent-gray-800 font-normal;
}

