/* Overrides rules from react-confirm-alert/src/react-confirm-alert.css */

.react-confirm-alert-overlay {
  @apply bg-regent-gray-900 bg-opacity-90;
}

.react-confirm-alert {
  @apply max-w-[90%];
}

.react-confirm-alert-body {
  width: unset;
  @apply sm:min-w-[540px] font-sans text-base p-7 text-left bg-white rounded text-gray-800 shadow-2xl;
}

.react-confirm-alert-button-group {
  @apply flex-wrap gap-2;
}

.react-confirm-alert-button-group > button {
  @apply m-0 py-0 px-5 flex-grow rounded;
}

.react-confirm-alert-button-group > button.destructive {
  @apply bg-red-700;
}

.react-confirm-alert-button-group > button.cancel {
  @apply bg-tpe-light text-black border border-solid border-black;
}

.react-confirm-alert-body > h1 {
  @apply text-2xl;
}

