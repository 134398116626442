.listItem {
  @apply flex flex-nowrap gap-2 py-2 px-3 overflow-y-hidden items-center border-b border-solid border-regent-gray-200 hover:bg-regent-gray-100;
}

.listItem.isSelected {
  @apply bg-curious-blue bg-opacity-10 hover:bg-regent-gray-200 border-b border-t border-dashed border-regent-gray-500;
}

.listItemInfo {
  flex: 1;
}

.listItem p {
  @apply mb-1;
}

.listItem p.subtitle {
  @apply inline-block text-xs w-[75%] text-regent-gray-600;
}

.listItem p.what3words:before {
  content: '///\200A'; /* character after the slashes is &hairsp; or &VeryThinSpace;, see https://dev.w3.org/html5/html-author/charref and http://www.evotech.net/articles/testjsentities.html*/
  color: #E11F26;
}
