.row.padded {
  @apply py-2;
}

.flexRow {
  @apply flex flex-row flex-wrap justify-start items-center;
}

/* .grow {
  @apply flex-grow;
} */

/* .spaced {
  @apply m-1;
} */

.col.padded.left {
  @apply ml-2;
}

.col.padded.right {
  @apply mr-2;
}

.col.inline > div {
  @apply inline-block;
}

.col.flexCol, .spacer {
  @apply flex-1;
}

p.padded {
  @apply pb-2;
}

.button-primary svg, .button-primary svg g {
  @apply text-white fill-current;
  /* fill: white; */
}

input.inline, button.inline, p.inline {
  @apply inline-block;
}

/**
 * Button group styling adapted from Bootstrap
 */

.btn-group {
  @apply my-0 mx-px;
  /* margin: 0 2px; */
}

.btn-group, .btn-group-vertical {
  @apply relative inline-block align-middle;
}

.btn-group-vertical>.btn, .btn-group>.btn {
  @apply relative float-left;
}

.btn-group>.btn:first-child {
  @apply ml-0;
}

.btn-group .btn+.btn, .btn-group .btn+.btn-group, .btn-group .btn-group+.btn, .btn-group .btn-group+.btn-group {
  @apply -ml-px;
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  @apply rounded-none;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  @apply rounded-r-none;
}

.btn-group>.btn:last-child:not(:first-child), .btn-group>.dropdown-toggle:not(:first-child) {
  @apply rounded-l-none;
}

.btn-group-vertical>.btn.active, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:hover, .btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus, .btn-group>.btn:hover {
  z-index: 2;
}

/* Buttons - general */

button[disabled] {
  @apply text-gray-500 border border-solid border-gray-200 cursor-not-allowed;
}

button:disabled {
  @apply cursor-not-allowed;
}

button.button-primary {
  @apply bg-picton-blue border border-solid border-picton-blue text-white transition-colors;
  @apply hover:bg-picton-blue-600 hover:border-picton-blue-600 hover:text-white;
  @apply focus:bg-picton-blue-600 focus:border-picton-blue-600 focus:text-white;
}

button.button-primary:disabled {
  @apply text-white bg-picton-blue-300 border-picton-blue-300;
}

button.button-success {
  @apply bg-green-600 border border-solid border-green-600 text-white transition-colors;
  @apply hover:bg-green-700 hover:border-green-700 hover:text-white;
  @apply focus:bg-green-700 focus:border-green-700 focus:text-white;
}

button.button-success:disabled {
  @apply text-white bg-green-300 border-green-300;
}

button.button-danger {
  @apply bg-red-600 border border-solid border-red-600 text-white transition-colors;
  @apply hover:bg-red-700 hover:border-red-700 hover:text-white;
  @apply focus:bg-red-700 focus:border-red-700 focus:text-white;
}

button.button-danger:disabled {
  @apply text-white bg-red-300 border-red-300;
}

button.button-cancel {
  @apply text-regent-gray-600 border border-solid border-regent-gray-600 transition-colors;
  @apply hover:text-regent-gray-700 hover:border-regent-gray-700;
  @apply focus:text-regent-gray-700 focus:border-regent-gray-700;
}

button.button-cancel:disabled {
  @apply text-regent-gray-300 border-regent-gray-300;
}

/* Default selected state for Skeleton default buttons */
button.selected {
  @apply text-regent-gray-700 bg-regent-gray-200 border-regent-gray-500 outline-none;
}

/* Small text button */
button.small {
  @apply tracking-normal normal-case;
}

/* Simple borderless button */

button.borderless {
  @apply border border-solid border-transparent;
}

button.simple {
  @apply inline-block my-0 border border-solid border-transparent;
  height: unset;
  line-height: unset;
}

button.simple:hover, button.borderless:hover {
  @apply border border-dotted border-regent-gray-300;
}

button.simple[disabled] {
  @apply border border-solid border-transparent;
}

button.simple svg {
  @apply relative;
}

button.simple[disabled] svg {
  @apply opacity-50;
}

/* Accent a simple button with our primary color */
button.simple.highlight {
  @apply text-curious-blue;
}

button.simple.highlight svg {
  @apply text-curious-blue fill-current;
}

/* Style a button as a link - needed to meet accessibility linting rules */

button.link, button.text {
  @apply inline-block p-0 mb-auto border-0 text-curious-blue no-underline normal-case text-base font-normal tracking-normal;
  background-color: unset;
  line-height: unset;
  height: unset;
}

small button.link, small button.text, button.text.small {
  @apply text-sm font-normal;
}

button.text {
  color: inherit;
  margin-bottom: unset;
}

button.link.simple, button.text.simple {
  @apply normal-case no-underline font-semibold border border-dotted border-transparent;
}

button.link.simple:hover, button.text.simple:hover {
  @apply border border-dotted border-regent-gray-600;
}

button.low-profile {
  @apply h-8 leading-8;
}

/* Callouts */

.bs-callout {
  @apply p-5 my-5 mx-0 border border-solid border-l-4 border-regent-gray-600 rounded-sm bg-tpe-light;
}

.bs-callout h4, .bs-callout h5, .bs-callout h6 {
  @apply mt-0 mb-1;
}

.bs-callout-primary {
  @apply border-0 border-l-4 border-curious-blue;
}

.bs-callout-primary h4, .bs-callout-primary h5 {
  @apply text-curious-blue;
}

.bs-callout-success {
  border-left-color: var(--color-skeleton-success);
}
/* 
.bs-callout-success h4, .bs-callout-success h5 {
  color: var(--color-skeleton-success);
} */

/* .bs-callout-warning {
  border-left-color:#f0ad4e
}

.bs-callout-warning h4 {
  color:#f0ad4e
}

.bs-callout-info {
  border-left-color:#3eb5da
}

.bs-callout-info h4 {
  color:#3eb5da
} */

.bs-callout-danger {
  border-left-color: var(--color-skeleton-danger);
}

.bs-callout-danger h4, .bs-callout-danger h5, .bs-callout-danger h6 {
  @apply mt-0;
  color: var(--color-skeleton-danger);
}